
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient, ChatClient } from '@/services/Services';
import VideoModal from '@/views/utenti/modals/videoModal.vue';
import DomandaRompighiaccio from '../components/domandaRompighiaccio.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        DomandaRompighiaccio
    }
})
export default class chatDetailModal extends Vue {

    @Prop() chatRoomIdentifier: string;

    readonly: boolean = true;
    
    chatRoom: OM.ChatRoomVM = null;
    chatMessages: OM.ChatMessage[] = [];
    etaUtente: number = 0;

    isSending: boolean = false;
    messageText: string = "";
    chatWriter: HTMLElement;

    loggedUserIdentifier: string = "";
    style: string = "";

    contactToSend: string = "";
    videoUrls: VM.VideoChat[] = [];
    loaded: boolean = false;
    
    created(){
        this.init();
    }

    init() {
        this.chatMessages = [];
        this.chatRoom = null;

        Promise.all([
            ChatClient.getChatRoom(this.chatRoomIdentifier),
            ChatClient.getLast50Messages(this.chatRoomIdentifier, 0)
        ])
        .then(xs => {
            this.chatRoom = xs[0];
            this.chatMessages = xs[1];

            Promise.all([
                AppUserClient.getUserAge(this.chatRoom.sender.appUserIdentifier),
                AppUserClient.getVideoUrl(this.chatRoom.sender.appUserIdentifier),
                AppUserClient.getVideoUrl(this.chatRoom.participants[1].appUserIdentifier),
            ])
            .then(ys => {
                this.etaUtente = ys[0];

                var item1 = new VM.VideoChat();
                item1.username = this.chatRoom.participants[0].profileName;
                item1.link = ys[1];
                this.videoUrls.push(item1);

                var item2 = new VM.VideoChat();
                item2.username = this.chatRoom.participants[1].profileName;
                item2.link = ys[2];
                this.videoUrls.push(item2);

                this.loggedUserIdentifier = this.chatRoom.sender.appUserIdentifier;
            })
        })
        .finally( () => {
            this.$nextTick( () => {
                this.loaded = true;
            })
        })
    }

    get hasSentDomanda(){
        return this.chatMessages.length > 0;
    }

    get canSendDomanda(){
        return this.remainingDomande > 0 && this.remainingMessages > 0;
    }
    get canSendMessage(){
        return this.hasSentDomanda && this.remainingMessages > 0;
    }

    get remainingDomande(){
        return this.chatRoom.maxDomande - this.userSentDomande;
    }
    get remainingMessages(){
        return this.chatRoom.remainingMessages;
        // return this.chatRoom.maxMessages - this.userSentMessages;
    }

    get userSentDomande(){
        return this.chatMessages.filter(x => x.senderIdentifier == store.state.loggedUser.identifier && x.isDomandaRompighiacchio).length;
    }
    get userSentMessages(){
        return this.chatMessages.filter(x => x.senderIdentifier == store.state.loggedUser.identifier && !x.isDomandaRompighiacchio).length;
    }

    get canSeeDomandaButton(){
        return this.chatMessages.length == 0 || this.chatMessages[this.chatMessages.length - 1].isDomandaRompighiacchio == true;
    }

    get canAskMoreMessages(){
        return this.userSentMessages <= 10;
    }

    get hasChatEnded(){
        return this.chatMessages.length > 0 && this.chatMessages[this.chatMessages.length - 1].isEndMessage == true;
    }

    get lastIsDomanda(){
        return this.chatMessages.length > 0 && this.chatMessages[this.chatMessages.length - 1].isDomandaRompighiacchio == true;
    }

    updateMessages() {
        ChatClient.getLast50Messages(this.chatRoom.chatRoomIdentifier, 0)
        .then(x => {
            this.chatMessages = x;
        })
    }

    senderIsMe(message: OM.ChatMessage) {
        if(message.senderIdentifier == this.loggedUserIdentifier)
            return true;
        else
            return false;
    }

    openVideoModal(videoUrl: string) {
        this.$opModal.show(VideoModal, {
            userCompleteName: this.chatRoom.sender.profileName,
            videoUrl: videoUrl,
            removeVideo: (reason: string) => {
                var model = new OM.TextIdentifier();
                model.identifier = this.chatRoom.sender.appUserIdentifier;
                model.text = reason;

                AppUserClient.removeVideoFromUser(model)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }
}
