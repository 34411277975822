
import { Options, Vue } from 'vue-class-component';
import { UserMatchClient } from '@/services/Services';
import * as OM from '@/Model';
import chatDetailModal from '../modals/chatDetailModal.vue';

@Options({})
export default class MatchesList extends Vue {

    items: OM.MatchSummaryVM[] = [];

    created(){
        UserMatchClient.getAllMatches()
        .then(x => {
            this.items = x;
        })
    }

    openChatDetailModal(identifier: string) {
        this.$opModal.show(chatDetailModal, {
            chatRoomIdentifier: identifier
        })
    }

}
